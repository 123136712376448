<template>
  <div class="excel">
    <Navbar></Navbar>
    <div class="excel-right">
      <div class="excel-titulo" @click="$router.push('/')">
        <img src="../assets/img/flechas/left_black.png" />
        <p>Carga de parámetros</p>
      </div>
      <p class="excel-subtitulo">Carga de parámetros</p>
      <div class="excel-row">
        <div class="excel-col">
          <input
            type="file"
            class="app-hide"
            id="excelEmbajadores"
            @change="handleFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button
            class="excel-btn-cargar"
            @click="abrirArchivo('excelEmbajadores')"
          >
            <img src="../assets/img/flechas/top_blue.png" />
            Cargar Embajadores Excel
          </button>
        </div>
        <div class="excel-col">
          <input
            type="file"
            class="app-hide"
            id="excelVendedores"
            @change="handleFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button
            class="excel-btn-cargar"
            @click="abrirArchivo('excelVendedores')"
          >
            <img src="../assets/img/flechas/top_blue.png" />
            Cargar Vendedores Excel
          </button>
        </div>
      </div>
    </div>
    <Loading v-show="mostrarLoading"></Loading>
  </div>
</template>

<script>
import XLSX from "xlsx";
import Loading from "@/components/Loading.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "excel",
  components: { Navbar, Loading },
  data() {
    return {
      embajadoresData: null,
      vendedoresData: null,
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    abrirArchivo(idInput) {
      this.idElegido = idInput;
      document.getElementById(idInput).value = "";
      document.getElementById(idInput).click();
    },
    handleFile(el) {
      this.mostrarLoading = true;
      let files = el.target.files,
        f = files[0];
      let reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        try {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });

          /* convert from workbook to array of arrays */
          // let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

          workbook.SheetNames.forEach(function (name) {
            let sheet = workbook.Sheets[name];
            data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            /* convert from array of arrays to workbook */
            let worksheet = XLSX.utils.aoa_to_sheet(data);
            let new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

            if (that.idElegido == "excelEmbajadores") {
              that.embajadoresData = data;
            } else if (that.idElegido == "excelVendedores") {
              that.vendedoresData = data;
            }
          });
          this.mostrarLoading = false;
          if (that.idElegido == "excelEmbajadores") {
            that.convertirEmbajadoresAJSON();
          } else if (that.idElegido == "excelVendedores") {
            that.convertirVendedoresAJSON();
          }
        } catch (err) {
          console.log(err);
        }
      };
      reader.readAsArrayBuffer(f);
    },
    convertirEmbajadoresAJSON() {
      this.mostrarLoading = true;
      const that = this;
      // FORMATEO LA DATA DEL EXCEL
      let bandera = 0,
        usuariosJSON = [];
      this.embajadoresData = this.embajadoresData.filter((m) => m[1] != null);
      this.embajadoresData.forEach(function (item) {
        if (item[1] != null) {
          if (bandera == 0) {
            bandera = 1;
          } else {
            let json = {
              email: item[8],
              nombre: item[6] + " " + item[7],
              codigo: item[3],
            };
            usuariosJSON.push(json);
          }
        }
      });
      console.log(usuariosJSON);
      this.$axios
        .post(this.$localurl + "/api/usuario/dueño", usuariosJSON)
        .then(function () {
          that.mostrarLoading = false;
          that.$eventHub.$emit("snackbar", "Embajadores cargadas");
        })
        .catch(function (response) {
          console.log(response);
          that.mostrarLoading = false;
          that.$eventHub.$emit("snackbar", "Error al cargar Embajadores");
        });
    },
    convertirVendedoresAJSON() {
      this.mostrarLoading = true;
      const that = this;
      // FORMATEO LA DATA DEL EXCEL
      let bandera = 0,
        usuariosJSON = [];
      this.vendedoresData = this.vendedoresData.filter((m) => m[1] != null);
      this.vendedoresData.forEach(function (item) {
        if (item[1] != null) {
          if (bandera == 0) {
            bandera = 1;
          } else {
            let json = {
              email: item[31],
              nombre: item[29] + " " + item[30],
              codigo: item[16],
            };
            usuariosJSON.push(json);
          }
        }
      });
      console.log(usuariosJSON);
      this.$axios
        .post(this.$localurl + "/api/usuario/vendedor", usuariosJSON)
        .then(function () {
          that.mostrarLoading = false;
          that.$eventHub.$emit("snackbar", "Vendedores cargadas");
        })
        .catch(function (response) {
          console.log(response);
          that.mostrarLoading = false;
          that.$eventHub.$emit("snackbar", "Error al cargar Vendedores");
        });
    },
  },
};
</script>

<style scoped src="../assets/css/views/excel.css"></style>
